import "../scss/styles.scss";
import PageCommon from "./pages/PageCommon.js";
import PageDefault from "./pages/PageDefault.js";

export default class App {
  static factory(cls) {
    return new App.classes[cls]();
  }
}

App.classes = { PageCommon, PageDefault };
