export default class Globals {
  static getBaseUrl() {
    try {
      App;
    } catch (e) {
      return "/";
    }
    return App.Env.BaseUrl;
  }
}
//let isDev = true;
let isDev = false;
//CM imposto isDev=false; per il mio pc
Globals.API_KEY = "WEB.0.0";

// Mostra nella console i dati di tracking
Globals.SHOW_TRACKING_DATA = false;

// Nasconde i console.log di sviluppo
Globals.SHOW_ALL_CONSOLE_LOG = true;

// Mostra nella console le chiamate del service manager
//Globals.SERVICE_MANAGER_SHOW_CALLS = false;

// Esclude totalmente il processo fake
try{
  Globals.SERVICE_MANAGER_USE_FAKE = true && App.Env.globalKeySet == "dev";
}catch(error){
  
}
