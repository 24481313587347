import PageCommon from "./PageCommon.js";
import Pqp from "../3x1010/Pqp.js";
import Globals from "../Globals.js";

export default class PageDefault extends PageCommon {
  constructor() {
    super();
  }
  init() {
    super.init(null);
    this.showPreloader(false);
    // Pqp.trace("PageDefault - Hello world");
    // dispaccio un evento globale
    try {
      this.dispatchInitEvent(TheDisp.EV_PAGETHIS_INITED);
    } catch (error) {
      // console.log(error);
    }

    var swiper = new Swiper(".mySwiper", {
      effect: 'coverflow',
      grabCursor: true,
      loop: true,
      autoplay: true,
      centeredSlides: true,
      spaceBetween: 0,
      slidesPerView: 6,
      coverflowEffect: {
        rotate: 20,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      on: {
        init: function () {
          $(".content-header").css('opacity', 1);
        },
      },
    });

    // Roadmap accordions
    if ($(".js-accordion-header")) {
      $(".accordion-content", ".accordion-learn-more").hide();
      $(".js-accordion-header").on("click", function (e) {
        $(this).toggleClass("accordion-open");
        if ($(this).hasClass("accordion-open")) {
          $(this).siblings(".accordion-content").slideDown(250);
        } else {
          $(this).siblings(".accordion-content").slideUp(250);
        }
      });
    }

    $(document).on("scroll", (e) => {
      let header = $("header").height();
      let navHome = $(".home-menu.desktop").height();
      if ($(window).scrollTop() >= header + 100) {
        $(".btn-up").css('opacity', 1);
      } else {
        $(".btn-up").css('opacity', 0);
      }
      if ($(window).scrollTop() >= navHome) {
        $(".internal-navigation.home-version").addClass("visible");
        //$(".icon-scroll", ".home-header").hide();
      } else {
        $(".internal-navigation.home-version").removeClass("visible");
        //$(".icon-scroll", ".home-header").show();
      }
    });

    $('.btn-open-mobile').on('click', ()=>{
      $('.panel-mobile-menu').addClass('open')
    })
    $('.single-mobile-voice').on('click', ()=>{
      $('.panel-mobile-menu').removeClass('open')
    })
    $('.close-btn').on('click', ()=>{
      $('.panel-mobile-menu').removeClass('open')
    })
  }

  get isHomepage() {
    return true;
  }
  
}
