import Pqp from "../3x1010/Pqp.js";
import Globals from "../Globals.js";

export default class PageCommon {
  constructor() {}
  init(metaInfo) {
    // Pqp.trace("PageCommon");

    if (this.hasTouch()) {
      // remove all :hover stylesheets
      try {
        // prevent exception on browsers not supporting DOM styleSheets properly
        for (var si in document.styleSheets) {
          var styleSheet = document.styleSheets[si];
          if (!styleSheet.rules) continue;

          for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
            if (!styleSheet.rules[ri].selectorText) continue;

            if (styleSheet.rules[ri].selectorText.match(":hover")) {
              styleSheet.deleteRule(ri);
            }
          }
        }
      } catch (ex) {}
    }

    // dispaccio un evento globale
    try {
      this.dispatchInitEvent(TheDisp.EV_PAGECOMMON_INITED);
    } catch (error) {
        // console.log(error);
    }
  }

  hasTouch() {
    return (
      "ontouchstart" in document.documentElement ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  dispatchInitEvent(evenTyepe) {
    // dispaccio un evento globale
    let ev = new Event(evenTyepe);
    ev.payload = { page: this };
    TheDisp.dispatchEvent(ev);
  }

  get isHomepage() {
    return false;
  }

  showPreloader(flag) {
    if (flag) {
      jQuery(".js-preloader").show();
    } else {
      jQuery(".js-preloader").hide();
    }
  }

  static showPreloader(flag) {
    if (flag) {
      jQuery(".js-preloader").show();
    } else {
      jQuery(".js-preloader").hide();
    }
  }

  track() {
    if (Globals.SHOW_TRACKING_DATA) {
      console.log("---tracking---");
      console.log("Event: " + PageCommon._normalizza_track_prop(o.event));
      console.log("Category: " + PageCommon._normalizza_track_prop(o.category));
      console.log(
        "Label: " + o.label
          ? PageCommon._normalizza_track_prop(o.label)
          : "n/a",
      );
    }

    let additional = {
      event_category: PageCommon._normalizza_track_prop(o.category),
    };
    if (o.label) {
      additional.event_label = PageCommon._normalizza_track_prop(o.label);
    }

    gtag("event", PageCommon._normalizza_track_prop(o.event), additional);
  }

  _normalizza_track_prop(s) {
    let ret = s.toLowerCase();
    ret = Pqp.safeReplace(ret, " ", "_");
    ret = Pqp.safeReplace(ret, "'", "_");
    ret = Pqp.safeReplace(ret, ".", "");
    ret = Pqp.safeReplace(ret, ",", "");
    ret = Pqp.safeReplace(ret, "(", "_");
    ret = Pqp.safeReplace(ret, ")", "");
    ret = Pqp.safeReplace(ret, "__", "_");
    return ret;
  }

  static disable(mc, lFlag, opacity = 1) {
    if (lFlag) {
      mc.css("pointer-events", "none");
      mc.css("opacity", opacity);
    } else {
      mc.css("pointer-events", "all");
      mc.css("opacity", 1);
    }
  }

  headerEventHandler(e) {
    if (e.type === Header.EVENT_OPEN_LOGIN) {
      this.showLogin();
    }
    if (e.type === Header.EVENT_OPEN_LOGOUT) {
      this.showTestIndirizzo();
      //this.showLogout();
    }
  }

  // libreia
  setInputFilter(textbox, inputFilter) {
    [
      "input",
      "keydown",
      "keyup",
      "mousedown",
      "mouseup",
      "select",
      "contextmenu",
      "drop",
    ].forEach(function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    });
  }
}
